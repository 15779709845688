<template>
  <auth-layout :title="$t('Reset Password')"
    :loading="loading">

    <template v-slot:body v-if="success == false">
      <v-text-field
          outlined
          v-model="form.email"
          :error-messages="firstError('form.email')"
          label="Email"
          :disabled="hasToken"
          type="email"
      ></v-text-field>
      <v-text-field
          v-if="hasToken"
          outlined
          v-model="form.password"
          :error-messages="firstError('form.password')"
          counter="25"
          :label="$t('New Password')"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
          @click:append="showPassword = !showPassword"
        />
        <v-text-field
          v-if="hasToken"
          outlined
          v-model="form.password_confirmation"
          :error-messages="firstError('form.password_confirmation')"
          counter="25"
          :label="$t('Confirm Password')"
          :type="showPassword ? 'text' : 'password'"
        />
    </template>

    <template v-slot:body v-else>
        <v-alert
          dense
          text
          type="success"
          class="my-15"
        >
          {{success}}
        </v-alert>
    </template>

    <template v-slot:footer >
      <router-link :to="'login'" class="text-decoration-none">{{$t('Login')}}</router-link>
      <v-spacer></v-spacer>
      <v-btn
          :loading="loading"
          v-if="success == false"
          type="submit"
          @click="submit"
          color="primary"
          class="px-4"
          dark
      >{{$t('Submit')}}</v-btn>
    </template>

  </auth-layout>
</template>
<script>
import { validationMixin } from 'vuelidate';
import {
  required, email, minLength, requiredIf,
} from 'vuelidate/lib/validators';
import axios from 'axios';
import FormValidationMixin from '../../lib/FormValidationMixin';

export default {
  mixins: [validationMixin, FormValidationMixin],
  validations: {
    form: {
      email: {
        email,
        required,
      },
      password: {
        required: requiredIf('hasToken'),
        txtMinLen: minLength(6),
      },
      password_confirmation: {
        required: requiredIf('hasToken'),
        txtMinLen: minLength(6),
      },
    },
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        token: '',
      },
      showPassword: false,
      loading: false,
      success: false,
    };
  },
  computed: {
    hasToken() {
      return Boolean(this.form.token.length);
    },
  },
  mounted() {
    if (this.$route.query.token && this.$route.query.email) {
      this.form.email = this.$route.query.email;
      this.form.token = this.$route.query.token;
    }
  },
  methods: {
    submit() {
      this.$v.$touch(); // validation
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      const route = this.hasToken ? 'reset-password' : 'reset-password-email';
      axios
        .post(route, {
          ...this.form,
        })
        .then(({ data }) => {
          this.loading = false;
          this.success = data;
          this.$store.dispatch('clearErrors');
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.loading = false;
        });
    },
  },
  destroyed() {
    this.$store.dispatch('clearErrors');
  },
};
</script>
