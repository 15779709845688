<template>
  <main-layout
    :title="$t('Page Not Found')">
  </main-layout>
</template>
<script>

export default {
  data() {
    return {
    };
  },
};
</script>
