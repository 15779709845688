<template>
  <auth-layout :title="$t('Verify Email')"
    :loading="loading">

    <template v-slot:body v-if="verify">
        <v-alert
          dense
          text
          type="success"
          class="my-15"
        >
          {{ $t('Your Email has been verified') }}
          <br>
          {{ $t('We will redirect to your panel in 3 secounds') }}
        </v-alert>
    </template>

    <template v-slot:footer >
      <router-link :to="'/login'" class="text-decoration-none">{{$t('Login')}}</router-link>
      <v-spacer></v-spacer>
      <!-- <v-btn
          :loading="loading"
          type="submit"
          @click="submit"
          color="primary"
          class="px-4"
          dark
      >Submit</v-btn> -->
    </template>

  </auth-layout>
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: true,
      verify: false,
    };
  },
  mounted() {
    const qs = (params) => Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
    const url = `/email/verify/${this.$route.params.id}/${this.$route.params.hash}?${qs(this.$route.query)}`;
    axios
      .get(url)
      .then(({ data: loginResponse }) => {
        this.loading = false;
        this.verify = true;
        this.$store.dispatch('loginUser', {
          ...loginResponse,
          delay: 3500,
        });
      })
      .catch((error) => {
        this.$store.dispatch('setErrors', error.response.data.errors);
        this.loading = false;
      });
  },
  destroyed() {
    this.$store.dispatch('clearErrors');
  },
};
</script>
